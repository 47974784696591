import { Card, CardMedia } from "@mui/material";

export const LogoCard = ( props: {company: any}) =>{
  if (props.company === undefined) {
    return <div></div>;
  }
  var path = props.company.code;
  if (props.company.configs.filter((config: any) => config.key === 'imageLogo').length === 0) {
    return <div><h2>{props.company.name}</h2></div>;
  }
  var logoName = props.company.configs.filter((config: any) => config.key === 'imageLogo')[0].value;
  
  if (logoName === undefined || logoName === '') {
    return <div></div>;
  }
  return (
    <Card>
      <CardMedia
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'left',
          backgroundPosition: 'left',
        }}
        style = {{ height: 225 }}
        image={require('../static/' + path + '/' + logoName)}
        title={props.company.name}
      />
    </Card>
  );
}  