import { Card, CardMedia } from "@mui/material";

export const LogoCard =() =>{
  return (
    <Card>
      <CardMedia
        style = {{ height: 225 }}
        image={require('../static/images/AP-logo.png')}
        title="AP Rengashuolto"
      />
    </Card>
  );
}  