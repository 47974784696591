/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingReportSchema } from '../models/BookingReportSchema';
import type { BookingSchema } from '../models/BookingSchema';
import type { SlotSchema } from '../models/SlotSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BookingsService {

    /**
     * Book
     * @param companyCode
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static bookApiBookingsPost(
        companyCode: string,
        requestBody: BookingSchema,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/bookings/',
            query: {
                'company_code': companyCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Report Bookings
     * @param companyCode
     * @param password
     * @param payload
     * @returns BookingReportSchema Successful Response
     * @throws ApiError
     */
    public static reportBookingsApiBookingsReportBookingsPost(
        companyCode: string,
        password: string,
        payload: string,
    ): CancelablePromise<Array<BookingReportSchema>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/bookings/report-bookings',
            query: {
                'company_code': companyCode,
                'password': password,
                'payload': payload,
            },
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Prebook
     * @param companyCode
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static prebookApiBookingsPrePost(
        companyCode: string,
        requestBody: BookingSchema,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/bookings/pre',
            query: {
                'company_code': companyCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Prebook
     * @param companyCode
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static prebookApiBookingsReleasePrePost(
        companyCode: string,
        requestBody: BookingSchema,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/bookings/releasePre',
            query: {
                'company_code': companyCode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel
     * @param companyCode
     * @param googleId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cancelApiBookingsCancelBookingGet(
        companyCode: string,
        googleId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/bookings/cancelBooking',
            query: {
                'company_code': companyCode,
                'google_id': googleId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Slots
     * @param companyCode
     * @returns SlotSchema Successful Response
     * @throws ApiError
     */
    public static getSlotsApiBookingsSlotsGet(
        companyCode: string,
    ): CancelablePromise<Array<SlotSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/bookings/slots',
            query: {
                'company_code': companyCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
