import { ThemeProvider } from "@emotion/react";
import { Badge, Button, CssBaseline, Dialog, DialogContent, Grid, Slide, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, createTheme } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DateCalendar, LocalizationProvider, PickersDay, fiFI } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import Favicon from "react-favicon";
import { useParams } from "react-router-dom";
import { BookingSchema, BookingsService, SlotSchema } from "../client";
import { LogoCard } from "../components/LogoCard";
import background from "../static/images/gradient-background.jpg";
import { WebSocketService } from "../ws/WebSocketService";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {

          backgroundImage: `url(${background})`,
          backgroundRepeat: 'no-repeat', 
          backgroundSize: 'cover'
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      light: '#FFFFFF',
      main:  '#C09000',
      dark:  '#808080',
      contrastText: '#eee',
    },
    // SLOTTINAPIT
    secondary: {
      light: '#FFFFFF',
      main:  '#C0C0C0',
      dark:  '#808080',
      contrastText: '#000',
    },
  },
  // typography: {
  //   fontFamily: 'Roboto',
  // },
});

const dialogTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const BookingPage = () => {
  const companyCode = useParams().companycode;

  let i = 0;
  const [today] = React.useState<any>(dayjs());
  const [value, setValue] = React.useState<any>(dayjs());
  const [slots, setSlots] = React.useState<SlotSchema[]>([]);
  const [chosenSlot, setChosenSlot] = React.useState<SlotSchema>();
  const [booking, setBooking] = React.useState<BookingSchema>();
  const [bookingResponse, setBookingResponse] = React.useState<any>();
  const [preBookingResponse, setPreBookingResponse] = React.useState<any>();
  const [preBookingAlert, setPreBookingAlert] = React.useState<any>();
  const [dateSelected, setDateSelected] = React.useState<boolean>();
  const [wsId, setWsId] = React.useState<string>('00');
  const socketRef: any = useRef(null);
  const [bookButtonEnabled, setBookButtonEnabled] = React.useState<boolean>();
  const [reg, setReg] = useState("");
  const [regError, setRegError] = useState("Anna rekisteritunnus");
  const [tel, setTel] = useState("");
  const [telError, setTelError] = useState("Anna puhelinnumero");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [faviconUrl, setFaviconUrl] = useState<string>(require('../static/images/favicon.ico'));

  const intervalMs = 60000;
  let interval;

  const checkOldSlots = () => {
    if(slots != undefined) {
      const now = new Date();
      let limitTime = new Date();
      limitTime.setMinutes(limitTime.getMinutes()+30);
      setSlots((slots) => slots.map(  (slot) => (slot != undefined && isTimeGreater(limitTime, slot.start as string)) ? {...slot, 'slotPreBooked': true  } : slot));
      setSlots((slots) => slots.filter(  (slot) => {return (isTimeGreater(now, slot.availabilityUntil_1 as string)) ? false : true;}));
    }
  }

  useEffect(() => {
    if (!socketRef.current) {
      if (companyCode) {
        socketRef.current = WebSocketService.getInstance(companyCode);
      }
    }
  }, []);

  const initialSlotsRequested = useRef(false)

  const listOfSlots = (date: Date) => {
    return slots.filter((slot: SlotSchema) => {
      const slotStart: Date = new Date(slot.start as string);
      if (slotStart.getDate() === date.getDate() && slotStart.getMonth() === date.getMonth() && slotStart.getFullYear() === date.getFullYear()) {
        return true;
      }
    });
  }

  const hourlyListOfSlots = ( daySlots: SlotSchema[]) => {
      let hourlyList: SlotSchema[][] = [[]];
      let hours: number[] = [];
      let row=-1;
      daySlots.forEach((slot: SlotSchema) => {
        const slotStart: Date = new Date(slot.start as string);
        const hour = slotStart.getHours();
        const min = slotStart.getMinutes();
        if(hours[hour] == undefined) {
          hours[hour]=1;
          row += 1;
          hourlyList[row] = [];
        }
        hourlyList[row][min/15] = slot;
      });
      return hourlyList;
  }

  // tätä kutsutaan jokaisen ui:lla olevan päivän kohdalla
  function ServerDay(props: any) {
    const { day, outsideCurrentMonth, ...other } = props;
    let isFreeTimes: boolean = false;

    // Tsekkaa onko slots-listassa tämä päivä
    slots.forEach((slot: SlotSchema) => {
      const slotStart: Date = new Date(slot.start as string);
      if (slotStart.getDate() === props.day.date() && slotStart.getMonth() === props.day.month()) {
        isFreeTimes = true;
      }
    });

    // const isSelected =
    //  !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
    const isSelected = !props.outsideCurrentMonth && isFreeTimes;
    
    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        color="success" variant="dot" invisible={isSelected ? false : true}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  }

//    const { day, outsideCurrentMonth, ...other } = props;

  //const displayTime = (date: string) => {
  const displayTime = (date: any) => {
    if(typeof date != "string") {return "";}
    const dateAsDate = new Date(date);
    const formattedDate = new Intl.DateTimeFormat('fi-FI', {
      timeZone: 'Europe/Helsinki',
      hour: '2-digit',
      minute: '2-digit',
    }).format(dateAsDate);
    return formattedDate;
  }

  const slotsUI = () =>{ 
    var dayNames = ['Su','Ma','Ti','Ke','To','Pe','La'];
    const showSlots: SlotSchema[] = value && listOfSlots(value.toDate());
    if (value && showSlots.length === 0) {
      return (
        <div>
          <h5>Ei vapaita aikoja {dayNames[value.$W]} {value.$D}.{value.$M+1}.{value.$y}</h5>
        </div>
      );
    }

    const hl: SlotSchema[][] = hourlyListOfSlots(showSlots);
 
    return (
      <Table padding="none" aria-label="ajat" sx={{borderCollapse: 'separate', borderSpacing: '0px 1px', badding: 'none'}}>
      <TableHead>
      <TableRow>
          <TableCell colSpan={4} align="center">
            <h2>{dayNames[value.$W]} {value.$D}.{value.$M+1}.{value.$y}</h2>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {hl.map((row, row_index) => (
          <TableRow>
            {[0, 1, 2, 3].map(cell_index => {
              if(row[cell_index] && row[cell_index].start) {
                let cell = row[cell_index];
                return ( 
                  <TableCell>
                    <Button sx={{fontFamily: "Arial Black", width:"50px"}}
                      color="secondary" 
                      disabled={cell.slotPreBooked}
                      id={cell.start}
                      key={cell.start}
                      variant="contained" onClick={(a: any) => {
                        const slot: SlotSchema | undefined = slots.find((slot: SlotSchema) => {
                          return slot.start === a.currentTarget.id;
                        });
                        setChosenSlot(slot);
                      }}>
                        {cell.start?displayTime(cell.start):""}
                    </Button>
                  </TableCell>
                )   
              } else {
                return ( 
                  <TableCell>
                    <Button sx={{fontFamily: "Arial Black", width:"50px"}}
                      color="secondary" 
                      disabled={true}
                      variant="contained"
                    >
                      -
                    </Button>
                </TableCell>
            )}})}
          </TableRow>
        ))}
        <TableRow />
      </TableBody>
      </Table>
    );
  }

  const closePreBookingAlert = () => {
    setPreBookingAlert(false);
    setChosenSlot(undefined)
  };

  const showDatePicker = () => {
    setDateSelected(false);
  };

  const handleSocketMessage = (event: MessageEvent) => {
    try {
      var socket_data = JSON.parse(event.data);
      if(socket_data['slotPreBooked'] != undefined) {
        if(socket_data['slotPreBooked'] == true) {
          setSlots((slots) => slots.map(  (slot) => isDateTimeEqual(slot.start as string, socket_data['start']) ? {...slot, 'slotPreBooked': true  } : slot));
        } else {
          setSlots((slots) => slots.map(  (slot) => isDateTimeEqual(slot.start as string, socket_data['start']) ? {...slot, 'slotPreBooked': false  } : slot));
        }
      }
      if(socket_data['ws_id']) {
        setWsId(socket_data['ws_id'])
      }

    } catch (e) {
      console.error(e); 
    }
  };
  socketRef.current && socketRef.current.addListener(handleSocketMessage);

  const isDateTimeEqual = (date1: string, date2: string) => {
    const date1AsDate = new Date(date1);
    const date2AsDate = new Date(date2);
    return date1AsDate.getTime() === date2AsDate.getTime();
  }

  const isTimeGreater = (time1: Date, time2str: string) => {
    const time2 = new Date(time2str);
    return time1.getTime() > time2.getTime();
  }

  useEffect(() => {
    if (chosenSlot) {
      setBooking({
        ...booking,
        start: chosenSlot.start,
        end: chosenSlot.end,
      });
      try {
        const prebooking: BookingSchema = {
          start: chosenSlot.start,
          end: chosenSlot.end,
          reg: '',
          tel: '',
          email: '',
          ws_id: wsId,
        }
        BookingsService.prebookApiBookingsPrePost(companyCode ?? '', prebooking as BookingSchema).then((response) => {
          setPreBookingResponse(response)
        });
      }
      catch (error) {
        console.log("error", error);
      }
    }
  }, [chosenSlot]);

  useEffect(() => {
    // load data from server
    try {
      if (!initialSlotsRequested.current) {
        initialSlotsRequested.current = true
        interval = setInterval(() => {checkOldSlots()}, intervalMs);
        BookingsService.getSlotsApiBookingsSlotsGet(companyCode ?? '').then((response) => {
          setSlots(response);
        });
      }
    }
    catch (error) {
      console.log("error", error);
    }
    
  }, []);

  useEffect(() => {
    if(preBookingResponse && preBookingResponse.status != "success") {
      setSlots((slots) => slots.map(  (slot) => isDateTimeEqual(slot.start as string, preBookingResponse.booking.start) ? {...slot, 'slotPreBooked': true  } : slot));

      setPreBookingAlert(true);
    }
  }, [preBookingResponse]);

  const makeReservation = () => {
    // send data to server
    const result = BookingsService.bookApiBookingsPost(companyCode ?? '', booking as BookingSchema).then((response) => {
      setBookingResponse(response);
    });
  }

  const releasePreBooking = () => {
    // send data to server
    const result = BookingsService.prebookApiBookingsReleasePrePost(companyCode as '', booking as BookingSchema).then((response) => {
      /* console.log("releaseBooking: response:", response); */
    });;
  }

  const displaySlotTimeText = (slot: SlotSchema) => {
    if(slot.start && slot.end) {

      const startAsDate = new Date(slot.start);
      const formattedStart = new Intl.DateTimeFormat('fi-FI', {
        timeZone: 'Europe/Helsinki',
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: '2-digit',
        minute: '2-digit',
      }).format(startAsDate);

      const endAsDate = new Date(slot.end);
      const formattedEnd = new Intl.DateTimeFormat('fi-FI', {
        timeZone: 'Europe/Helsinki',
        hour: '2-digit',
        minute: '2-digit',
      }).format(endAsDate);
      return formattedStart + "-" + formattedEnd;
      }
    }

  function bookingUI(): React.ReactNode {
    const handleInputs = (e: any) => {
      setBooking({...booking, 'reg': reg, 'tel': tel, 'email': email});
    }

    const handleRegChange = (e: any) => {
      const newValue = e.target.value;
      const errorMessage = regValidator(newValue);
      setReg(newValue);
      setRegError(errorMessage); // Explicitly set the type to string
      if(errorMessage == "" && telError == "" && emailError == "") {
        setBookButtonEnabled(true);
      }else {
        setBookButtonEnabled(false);
      }
    };

    const handleTelChange = (e: any) => {
      const newValue = e.target.value;
      const errorMessage = telValidator(newValue);
      setTel(newValue);
      setTelError(errorMessage); // Explicitly set the type to string
      if(regError == "" && errorMessage == "" && emailError == "") {
        setBookButtonEnabled(true);
      }else {
        setBookButtonEnabled(false);
      }
    };

    const handleEmailChange = (e: any) => {
      const newValue = e.target.value;
      const errorMessage = emailValidator(newValue);
      setEmail(newValue);
      setEmailError(errorMessage); // Explicitly set the type to string
      if(regError == "" && telError == "" && errorMessage == "") {
        setBookButtonEnabled(true);
      }else {
        setBookButtonEnabled(false);
      }
    };

    const regValidator = (value: any) => {
      if (value.length < 3) return "Rekisteritunnuksen tulee olla pidempi";
      if (value.length > 15) return "Rekisteritunnuksen tulee olla lyhyempi";
      if (!/^[a-zA-Z0-9 \-]+$/.test(value))
        return "Rekisteritunnuksessa tulee olla ainoastaan kirjaimia ja numeroita. Voi olla ilman viivaa";
      return "";
    };
    const telValidator = (value: any) => {
      if (value.length < 7) return "Puhelinnumeron tulee olla pidempi";
      if (value.length > 20) return "Puhelinnumeron tulee olla lyhyempi";
      if (!/^[\d\+\-\s]+$/.test(value))
        return "Puhelinnumerossa tulee numeroita. Voi olla ilman viivoja ja välilyöntejä";
      return "";
    };
    const emailValidator = (value: any) => {
      // Test if email is valid
      if(value == "") return "";

      if (value.length < 5) return "Sähköpostin tulee olla pidempi";
      if (value.length > 50) return "Sähköpostin tulee olla lyhyempi";
      if (!/^[a-zA-Z0-9\.\-\_]+@[a-zA-Z0-9\.\-\_]+\.[a-zA-Z0-9\.\-\_]+$/.test(value))
        return "Anna oikea sähköpostiosoite";
      
      //if (!/^[a-zA-Z0-9\.\-\_]*$/.test(value))
      //  return "Rekisteritunnuksessa tulee olla ainoastaan kirjaimia ja numeroita. Voi olla ilman viivaa";
      return "";
    };

    return (
      <Stack spacing={2} direction="column">
        <h5>Tee varaus {chosenSlot?displaySlotTimeText(chosenSlot):""}</h5>
        <TextField id="outlined-basic" label="Ajoneuvon rekisterinumero" variant="outlined" onChange={handleRegChange} helperText={regError} onBlur={handleInputs}/>
        <TextField id="outlined-basic" label="Puhelinnumero" variant="outlined" onChange={handleTelChange}  helperText={telError} onBlur={handleInputs}/>
        <TextField id="outlined-basic" label="Sähköposti" variant="outlined" onChange={handleEmailChange}  helperText={emailError} onBlur={handleInputs}/>
        <Button variant="contained"  color="secondary" disabled={(bookButtonEnabled) ? false : true} onClick={() => {
          makeReservation();
        }}>
          Varaa
        </Button>
        <Button variant="contained"  color="secondary" onClick={() => {
          releasePreBooking()
          setChosenSlot(undefined)
        }}>
          Peruuta
        </Button>
      </Stack>
    );
  }


  const displayTimesText = (booking: BookingSchema) => {
    if(booking.start && booking.end) {

      const startAsDate = new Date(booking.start);
      const formattedStart = new Intl.DateTimeFormat('fi-FI', {
        timeZone: 'Europe/Helsinki',
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: '2-digit',
        minute: '2-digit',
      }).format(startAsDate);

      const endAsDate = new Date(booking.end);
      const formattedEnd = new Intl.DateTimeFormat('fi-FI', {
        timeZone: 'Europe/Helsinki',
        hour: '2-digit',
        minute: '2-digit',
      }).format(endAsDate);
      return formattedStart + "-" + formattedEnd;
      }
    }

//         <Item></Item>

  function bookingResponseUI(): React.ReactNode {
    const bookingTimesText = bookingResponse ? displayTimesText(bookingResponse.booking) :"";
      
    return (
      <Stack spacing={2} direction="column" alignItems={'left'}>
        <Typography align="left">
          {bookingResponse && bookingResponse.status === 'success' && (
            <div>
              <h5 text-align="left">Varaus onnistui</h5>
              <div text-align="left">
                <p text-align="left">Aika: {bookingTimesText}</p>
                <p text-align="left">Ajoneuvon rekisterinumero: {bookingResponse.booking.reg}</p>
                <p>Puhelinnumero: {bookingResponse.booking.tel}</p>
              </div>
            </div>
          )}
          {bookingResponse && bookingResponse.status === 'conflict' && (
            <div>
              <h5>Varaus epäonnistui</h5>
              <div>
                <p>Yritä hetken kuluttua uudestaan</p>
                <p>{bookingResponse.error}</p>
              </div>
            </div>
          )}
        </Typography>
      </Stack>
    );
  }

  // style={{ width: '800px' }}
  return (
    <div className="App" >
      <Favicon url={faviconUrl} />
      <ThemeProvider theme={theme}>
      <CssBaseline />  
      <header className="App-header">
        <Grid container direction="row" spacing={2} xs={12} justifyContent="flex-start" >
          <Grid item xs={12}>
            <LogoCard />
          </Grid>
          <Grid item xs={12} sm={12}  justifyContent="flex-start" style={{display: (!dateSelected && !chosenSlot && !bookingResponse) ? '' : 'none'}} >
            <h4>Varaa renkaiden vaihto</h4>
            <LocalizationProvider localeText={fiFI.components.MuiLocalizationProvider.defaultProps.localeText} dateAdapter={AdapterDayjs} adapterLocale={"fi"}>
              <DateCalendar
                defaultValue={today}
                onChange={(newValue) => {
                  setValue(newValue);
                  setDateSelected(true);
                }
                }
                /*onMonthChange={(newValue) => console.log("onMonthChange", newValue)}*/
                slots={{
                  day: ServerDay,
                }}
                sx={{marginLeft: "1px"}}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} sx={{maxWidth: '400px'}} style={{display: (dateSelected && !chosenSlot && !bookingResponse) ? '' : 'none'}} >
            {slotsUI()}
            <br />
            <Button variant="contained" color="primary" onClick={showDatePicker} autoFocus>
              Palaa päivän valintaan
            </Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} alignItems={'left'}>
          <Grid item xs={1} sm={1}>
          </Grid>
          <Grid item xs={6} sm={10} style={{display: (chosenSlot && preBookingResponse && preBookingResponse.status == "success" && !bookingResponse) ? '' : 'none'}} alignItems={'left'}>
            {bookingUI()}
          </Grid>
          <Grid item xs={6} sm={10} alignItems={'left'}>
          </Grid>
          <Grid item xs={6} sm={10} style={{display: bookingResponse ? '' : 'none'}} alignItems={'left'}>
            {bookingResponseUI()}
          </Grid>
        </Grid>
        <Dialog 
          open={preBookingAlert}
          TransitionComponent={dialogTransition}
          keepMounted
        >  
          <DialogContent>
            Tämän ajan varaaminen ei onnistu.
            <Button onClick={closePreBookingAlert} autoFocus>
              Sulje
            </Button>
          </DialogContent>
        </Dialog>
      </header>
      </ThemeProvider>
    </div>
  );
}
