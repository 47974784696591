/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanySchema } from '../models/CompanySchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompaniesService {

    /**
     * Login
     * @param companyCode
     * @param email
     * @param credentials
     * @param statusCode
     * @returns any Successful Response
     * @throws ApiError
     */
    public static loginApiCompaniesCompanyCodeLoginPost(
        companyCode: string,
        email: string,
        credentials: string,
        statusCode?: any,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/companies/{company_code}/login',
            path: {
                'company_code': companyCode,
            },
            query: {
                'email': email,
                'credentials': credentials,
                'status_code': statusCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Company
     * @param companyCode
     * @returns CompanySchema Successful Response
     * @throws ApiError
     */
    public static getCompanyApiCompaniesCompanyCodeGet(
        companyCode: string,
    ): CancelablePromise<CompanySchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/companies/{company_code}',
            path: {
                'company_code': companyCode,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
