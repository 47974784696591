import { useState } from "react";
import Favicon from "react-favicon";
import { useParams } from "react-router-dom";
import { BookingsService } from "../client";

export const BookingCancellationPage = () => {
  const [faviconUrl, setFaviconUrl] = useState<string>(require('../static/images/favicon.ico'));
  const companyCode = useParams().companycode;
  const eventId = useParams().eventid;

  const result = BookingsService.cancelApiBookingsCancelBookingGet(companyCode ?? '', eventId ?? '').then((response) => {
    console.log("BookingCancellationPage: response:", response);
  });;;

  return (
    <div>
      <Favicon url={faviconUrl} />
      <h1>Varauksenne on peruttu onnistuneesti</h1>
    </div>
  );
}