import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import Favicon from "react-favicon";
import { useParams } from "react-router-dom";
import { BookingReportSchema } from "../client";
import { BookingsService } from "../client/services/BookingsService";

export const AdminPage = () => {
  const [faviconUrl, setFaviconUrl] = useState<string>(require('../static/ap/favicon.ico'));
  const companyCode = useParams().companycode;
  const eventId = useParams().eventid;
  const [dailyBookings, setDailyBookings] = React.useState<BookingReportSchema[]>([]);
  const [password, setPassword] = React.useState<string>('')
  const [login, setLogin] = React.useState<boolean>(false)
  
  const getDailyBookingsList = (timeDescr: string) => {
    //alert('Button clicked!');
    //let timeDescr='today';
    //let timeDescr='nextOpeningDay';

    BookingsService.reportBookingsApiBookingsReportBookingsPost(companyCode ?? '', password, timeDescr)
      .then((response) => {
        console.log("AdminPage: response:", response);
        setDailyBookings(response);
      })
      .catch((error) => {
        setLogin(false);
        setPassword('');
        console.error("Unauthorized: Invalid password or session expired.");
      });
  }

  const loginButtonPressed = () => {
    //alert('Button clicked!');
    setLogin(true);
  }

  useEffect(() => {
    console.log("DailyBookings: ");
    console.log(dailyBookings);
  }, [dailyBookings]);

  const dailyBookingsUI = () =>{
    if(dailyBookings.length === 0) {
      return <h1>no way</h1>
    }
    let date = dailyBookings[0]?.start?.match('^([0-9\-]*)') ?? '';
    let date0 = date[0] ?? '';

    const dateAsDate = new Date(date0);
    const formattedDate = new Intl.DateTimeFormat('fi-FI', {
      dateStyle: 'full',
      timeZone: 'Europe/Helsinki'
    }).format(dateAsDate);

    //hour: '2-digit',
    //minute: '2-digit',
    //timeStyle: 'long',


    return (
      <>
      <h3>{formattedDate}</h3>
      <Table padding="none" aria-label="ajat" sx={{borderCollapse: 'separate', borderSpacing: '0px 1px', badding: 'none'}}>
        <TableBody>
          <TableRow>
            <TableCell></TableCell>
          </TableRow>
        {dailyBookings.map((row, row_index) => { 
            let bg='#8f8';

            let formattedTime = '';
            var formattedDuration;
            if(row.start != undefined && row.end != undefined) {
              let startTimeAsDate = new Date(row.start);
              let endTimeAsDate = new Date(row.end);
              if(startTimeAsDate != undefined && endTimeAsDate != undefined) {
                formattedTime = new Intl.DateTimeFormat('fi-FI', {hour: '2-digit', minute: '2-digit', timeZone: 'Europe/Helsinki'}).format(startTimeAsDate) ?? '';
                var duration = endTimeAsDate.valueOf() - startTimeAsDate.valueOf();
                formattedDuration = duration / 60000;
                //var duration = new Date(endTimeAsDate.valueOf() - startTimeAsDate.valueOf());
                //formattedDuration = endTimeAsDate.diff(startTimeAsDate, 'minutes');
                //formattedDuration = new Date(endTimeAsDate - startTimeAsDate);
                //formattedDuration = new Intl.DateTimeFormat('fi-FI', {hour: '2-digit', minute: '2-digit', timeZone: 'Europe/Helsinki'}).format(duration) ?? '';
                //formattedDuration = duration;
              }
            }
            // date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }), 
            if(row.summary && RegExp('VARAUS').test(row.summary)) {bg='#eee'}; 
            return (
              <TableRow style={{background:bg}}>
                <TableCell>{row_index+1}</TableCell>
                <TableCell>{formattedTime}</TableCell>
                <TableCell>{formattedDuration}min</TableCell>
                <TableCell>{row.summary}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
          )})}
        </TableBody>
      </Table>
      </>
    );
  };

            {/*
            {{
            }}
                            
            */}


                {/*<TableCell>{row.summary}</TableCell>*/}

  return (
    <div>
      <Favicon url={faviconUrl} />
      <h1>Admin</h1>
      {!login && (
        <>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button onClick={() => loginButtonPressed()}>Login</button>
        </>
      )}
      <button onClick={() => getDailyBookingsList('today')}>Tänään</button>
      <button onClick={() => getDailyBookingsList('nextOpeningDay')}>Seuraava aukiolopäivä</button>
      {dailyBookingsUI()}
    </div>
  );
}
