import 'dayjs/locale/fi';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { AdminPage } from './pages/AdminPage';
import { BookingCancellationPage } from './pages/BookingCancellationPage';
import { BookingPage } from './pages/BookingPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:companycode" element={<BookingPage />} />
        <Route path="/:companycode/admin" element={<AdminPage />} />
        <Route path="/:companycode/cancel/:eventid" element={<BookingCancellationPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;