import { SlotSchema } from "../client/models/SlotSchema";

// typedef for callback function message
type WSMessageType = {
  message: string;
  date: Date;
  slots: Array<SlotSchema>;
  prebooked: SlotSchema;
}

export class WebSocketService {
  private static instance: WebSocketService;
  private ws: WebSocket;
  private wsInitialized: boolean = false;

  private constructor(companyCode: string = "") {
    let url = "";
    if (window.location.port) {
      const port = window.location.hostname === 'localhost' ? '8000' : window.location.port
      url = "ws://" + window.location.hostname + ":" + port + "/ws/" + companyCode;
    } else if (companyCode !== "" ) {
      url = "wss://" + window.location.hostname + "/ws/"+ companyCode;
    }
    console.log("url: " + url);
    this.ws = new WebSocket(url)
    this.ws.onopen = () => {
      console.log("WebSocket opened!");
    }
    this.ws && this.isConnected();
  }

  public isConnected(): boolean {
    if (this.ws.readyState === WebSocket.CONNECTING) {
      console.log("Still connecting to WebSocket...");
    }
    else if (this.ws.readyState === WebSocket.OPEN) {
      console.log("WebSocket is open!");
    }
    else if (this.ws.readyState === WebSocket.CLOSING) {
      console.log("WebSocket is closing...");
    }
    else if (this.ws.readyState === WebSocket.CLOSED) {
      console.log("WebSocket is closed!");
    }
    else {
      console.log("WebSocket is in an unknown state!");
    }
    return this.ws.readyState === WebSocket.OPEN;
  }

  public static getInstance(companyCode: string = ""): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService(companyCode);
    }

    return WebSocketService.instance;
  }

  public getWebSocket(): WebSocket {
    return this.ws;
  }

  public sendMessage(message: any) {
    this.ws.send(message);
  }

  public addListener(handleSocketMessage: any){
    this.ws.addEventListener('message', handleSocketMessage);
  }
}